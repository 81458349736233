import { Security } from 'common/security';

export class TabSites {
    static inject = [Security];

    constructor(security) {
        this.security = security;
    }

    activate(model) {
        this.agentId = model.agentId;
        this.viewingMyProfile = this.security.isAuthenticatedMember(this.agentId);
		this.canHaveAgentSite = this.security.canHaveAgentSite();
    }
}

import { I18n } from 'common/i18n';
import { Analytics } from 'services/analytics';

export class AgentGrid {
    static inject = [I18n, Analytics];
    _i18n;
    _analytics;

    cols;
    agents;

    groupBy;
    groupBys = [];

    constructor(i18n, analytics) {
        this._i18n = i18n;
        this._analytics = analytics;
        this._initializeGroupBys();
    }

    _initializeGroupBys() {
        this.groupBys.push({ display: 'All Agents', column: undefined });
        this.groupBys.push({ display: 'By Direct Upline', column: 'directUpline' });
        this.groupBys.push({ display: 'By Team', column: 'team' });
        this.groupBys.push({ display: 'By Agency', column: 'agency' });
        this.groupBys.push({ display: 'By Master Agency', column: 'masterAgency' });
        this.groupBys.push({ display: 'By Life Level', column: 'lifeLevelName' });
        this.groupBys.push({ display: 'By Annuity Level', column: 'annuityLevelName' });
        this.groupBys.push({ display: 'By HMA Level', column: 'hmaLevelName' });
        this.groupBys.push({ display: 'By State', column: 'state' });
        this.groupBy = this.groupBys[0];
    }

    setGroupBy(groupBy) {
        this.groupBy = groupBy;
        this.groupByColumn = groupBy.column;
    }

    activate() {
        this._initializeGrid();
        this._load();
    }

    async _load() {
        try {
            this.agents = await this._analytics.forAgents();
        } catch (err) {
            console.log(err);
        }
    }

    _initializeGrid() {
        this.cols = [
            {
                field: 'agentID',
                headerName: this._i18n.tr('profile-agent-id'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'firstName',
                headerName: this._i18n.tr('first-name'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'lastName',
                headerName: this._i18n.tr('last-name'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'dateOfBirth',
                type: 'dateColumn',
                headerName: this._i18n.tr('date-of-birth'),
                cellDataType: 'dateString',
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'email',
                headerName: this._i18n.tr('email'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'streetAddress',
                headerName: this._i18n.tr('street-address'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'streetAddress2',
                headerName: this._i18n.tr('profile-street-address2'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'city',
                headerName: this._i18n.tr('city'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'state',
                headerName: this._i18n.tr('state'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'zip',
                headerName: this._i18n.tr('zip'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'county',
                headerName: this._i18n.tr('county'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'businessPhone',
                headerName: this._i18n.tr('business-phone'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'cellPhone',
                headerName: this._i18n.tr('profile-cell'),
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'isLicensedLife',
                headerName: this._i18n.tr('profile-licenses-life-license'),
                cellDataType: 'boolean',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'isLicensedHealth',
                headerName: this._i18n.tr('profile-licenses-health-license'),
                cellDataType: 'boolean',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'registeredDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('registered'),
                cellDataType: 'dateString',
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'directUpline',
                headerName: 'Direct Upline',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'historicalTeam',
                headerName: 'Historical Team',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'team',
                headerName: 'Team',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'historicalAgency',
                headerName: 'Historical Agency',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'agency',
                headerName: this._i18n.tr('agency'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'historicalMasterAgency',
                headerName: 'Historical Master Agency',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'masterAgency',
                headerName: 'Master Agency',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'lifeLevelName',
                headerName: this._i18n.tr('life-level'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'annuityLevelName',
                headerName: this._i18n.tr('annuity-level'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'hmaLevelName',
                headerName: this._i18n.tr('hma-level'),
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'numberOfFirstLineAgents',
                type: 'numericColumn',
                headerName: '# First Line Agents',
                cellDataType: 'number'
            },
            {
                field: 'isActive',
                headerName: this._i18n.tr('active'),
                cellDataType: 'boolean',
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'inactiveReason',
                headerName: 'Inactive Reason',
                enableRowGroup: false
            },
            {
                field: 'termsDate',
                type: 'dateColumn',
                headerName: 'Terms Date',
                cellDataType: 'dateString',
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'lastLoginDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('last-login'),
                cellDataType: 'dateString',
                enableRowGroup: false,
                hide: false
            },
            {
                field: 'nationalProducerNumber',
                headerName: this._i18n.tr('national-producer-number'),
                enableRowGroup: false
            },
            {
                field: 'isTeam',
                headerName: 'Is Team',
                cellDataType: 'boolean',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'isAgency',
                headerName: 'Is Agency',
                cellDataType: 'boolean',
                enableRowGroup: true,
                hide: false
            },
            {
                field: 'firstContractDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('agent-first-contract'),
                cellDataType: 'dateString',
                hide: false
            },
            {
                field: 'firstPolicySubmittedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('agent-first-policy-submitted'),
                cellDataType: 'dateString',
                hide: false
            },
            {
                field: 'firstPolicyPaidDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('agent-first-policy-paid'),
                cellDataType: 'dateString',
                hide: false
            },
            {
                field: 'firstRecruitDate',
                type: 'dateColumn',
                headerName: 'First Recruit',
                cellDataType: 'dateString',
                hide: false
            },
       ];
    }
}
import environment from '../config/environment.json';
import { PLATFORM } from 'aurelia-pal';
import 'bootstrap';
import 'jquery';
import 'jquery-hoverintent/jquery.hoverIntent.min.js';
import 'common/polyfills';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { HTMLSanitizer } from 'aurelia-templating-resources';
import { setupValidationRules } from './validation/custom-rules';
import { c } from 'common/common';
import { Sanitizer } from 'common/sanitizer';

export function configure(aurelia) {
    let localeName = '';
    const i18nSetup = {
        backend: {
            loadPath: `${environment.api}/api/content/ui-term/{{lng}}/{{ns}}?v=${encodeURIComponent(c.Helpers.uniqueId())}`
        },
        lng: localeName,
        attributes: ['t','i18n'],
        getAsync: true,
        sendMissing: false,
        fallbackLng: 'en',
        debug: false,
        defaultNS: 'core',
        ns: ['core', 'profile'],
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng',
            caches: [],
        },
    };

    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('resources/data-grid/index'))
        .feature(PLATFORM.moduleName('resources/index'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
            instance.i18next.use(XHR);
            instance.i18next.use(LngDetector);

            // adapt options to your needs (see http://i18next.com/docs/options/)
            return instance.setup(i18nSetup);
        })
        .plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
            config.useDefaults();
            config.settings.lock = true;
            config.settings.startingZIndex = 2002;
            config.settings.centerHorizontalOnly = true;
            config.settings.ignoreTransitions = true;
        })
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('bcx-aurelia-reorderable-repeat'))
        .singleton(HTMLSanitizer, Sanitizer)
    ;

    aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    setupValidationRules();

    aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
import { Security } from 'common/security';

export class TabAgentTrackingSystem {
    static inject = [Security];
    _security;
    showAddPipeline;
    memberId;

    constructor(security) {
        this._security = security;
    }

    activate(model) {
        this.showAddPipeline = this._security.isAuthenticatedMember(model.id);
        this.memberId = model.id;
    }
}

import { WIDGET, COMMISSION } from 'common/constants';

export class TabCommissions {
    constructor() {}

    activate(model) {
        this.config = {
            memberId: model.memberId,
            groupBy: COMMISSION.GroupBy.Date,
            widgetPeriod: WIDGET.Period.Year,
        };
    }
}

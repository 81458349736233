import { bindable } from 'aurelia-framework';
import { ProductionService } from 'services/production-service';
import { Carriers } from 'services/carriers';

export class AgentCardWidgets {
    static inject = [ProductionService, Carriers];
    @bindable agentId;

    _production;
    _carriers;

    carriers = [];
    carrierFilters = [{ value: '', keys: ['name', 'code'] }];

    constructor(production, carriers) {
        this._production = production;
        this._carriers = carriers;
    }

    selectCarrier(carrier) {
        try {
            this.carrier = carrier;
            if (this.carrier && this._pp && this._pp.byCarrier) {
                const carrierPpData = this._pp.byCarrier.find(x => x.carrierId === carrier.id);
                if (carrierPpData) {
                    this._setPpData(carrierPpData);
                    return;
                }
            }
            this.carrier = undefined;
            this._setPpData(this._pp);
        } catch (err) {
            console.log(err);
        }
    }

    nextCarrier() {
        try {
            if (!this.carriers.length) return;
            if (!this.carrier) {
                this.selectCarrier(this.carriers[0]);
                return;
            }
            const idx = this.carriers.findIndex(x => x.id === this.carrier.id);
            if (idx === this.carriers.length) {
                this.selectCarrier();
                return;
            }
            this.selectCarrier(this.carriers[idx + 1]);
        } catch (err) {
            console.log(err);
        }
    }

    previousCarrier() {
        try {
            if (!this.carriers.length) return;
            if (!this.carrier) {
                this.selectCarrier(this.carriers[this.carriers.length - 1]);
                return;
            }
            const idx = this.carriers.findIndex(x => x.id === this.carrier.id);
            if (idx === 0) {
                this.selectCarrier();
                return;
            }
            this.selectCarrier(this.carriers[idx - 1]);
        } catch (err) {
            console.log(err);
        }
    }

    agentIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.agentId) return;
        try {
            this._pp = await this._production.placementAndPersistency(this.agentId);
            if (this._pp == null) {
                this._pp = null;
                return;
            }
            const carriers = await this._carriers.all();
            if (this._pp.byCarrier) {
                this._pp.byCarrier.forEach(c => {
                    const carrier = carriers.find(x => x.id === c.carrierId);
                    if (!carrier) return;
                    carrier.hasPolicies = c.hasPolicies;
                    if (carrier.hasPolicies) this.carriers.push(carrier);
                });
            }
            this._setPpData(this._pp);
        } catch (err) {
            console.log(err);
        }
    }

    _setPpData(data) {
        this.placementBreaks = [data.placement.targetPercentage, data.placement.targetPercentage];
        this.persistency3mBreaks = [data.persistency3Month.targetPercentage, data.persistency3Month.targetPercentage];
        this.persistency6mBreaks = [data.persistency6Month.targetPercentage, data.persistency6Month.targetPercentage];
        this.persistency9mBreaks = [data.persistency9Month.targetPercentage, data.persistency9Month.targetPercentage];
        this.persistency13mBreaks = [data.persistency13Month.targetPercentage, data.persistency13Month.targetPercentage];
        this.data = data;
    }
}
import { Security } from 'common/security';
export class TabToDos {
    static inject = [Security];
    _security;
    memberId;
    showToDosForAgent = false;

    constructor(security) {
        this._security = security;
    }

    activate(model) {
        this.showToDosForAgent = model.memberId !== this._security.authenticatedMemberId;
        this.memberId = model.memberId;
    }
}

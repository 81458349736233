import { DialogController } from 'aurelia-dialog';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { c } from './../../../common/common';

export class ChangePasswordDialog {
    static inject = [DialogController, Api, Notifier, NewInstance.of(ValidationController)];
    dialogController;
    _api;
    _notifier;

    currentPassword = '';
   	newPassword = '';

    constructor(dialogController, api, notifier, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._notifier = notifier;
        this._api = api;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('currentPassword').required()
            .ensure('newPassword').required().minLength(6).matches(c.RegEx.strongPassword)
            .on(this);
    }

    activate(model) {
        this.validationController.reset();
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const model = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
            }
            this._api.post('member/change-password', model);
            this._notifier.success('profile-password-updated-message');
            this.dialogController.ok({ currentPassword: this.currentPassword, newPassword: this.newPassword });
        } catch (err) {
            this._notifier.generalError();
        }
    }
}
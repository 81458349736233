import { observable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Members } from 'services/members';
import { ProductionService } from 'services/production-service';
import { CACHE_PERIOD, CACHE_TYPE } from 'services/production-service';
import moment from 'moment';

export class TabPerformance {
    static inject = [Security, Members, ProductionService];
    _security;
    _members;
    _production;

    memberId;

    CACHE_TYPE = CACHE_TYPE;
    CACHE_PERIOD = CACHE_PERIOD;
    cacheTypes = CACHE_TYPE.options();
    @observable({ changeHandler: 'closeClickthrough' }) cacheType = CACHE_TYPE.Personal;
    cachePeriods = CACHE_PERIOD.options();
    @observable({ changeHandler: 'closeClickthrough' }) cachePeriod = CACHE_PERIOD.Yesterday;

    show = 'status';

    constructor(security, members, production) {
        this._security = security;
        this._members = members;
        this._production = production;
    }

    activate(model) {
        this.memberId = model.memberId;
        this._initialize();
    }

    async _initialize() {
        try {
            this.access = await this._members.access(this.memberId);
            this.cacheTypes = CACHE_TYPE.options(this.access);
        } catch (err) {
            console.log(err);
        }
    }

    loaded(detail) {
        try {
            this.period = detail.period;
        } catch (err) {
            console.log(err);
        }
    }

    selectCachePeriod(cp) {
        this.cachePeriod = cp;
    }

    async clicked(detail) {
        try {
            this.loading = true;
            this.chartData = [];
            this.show = 'policies';
            this.policyPremiumType = detail.dateType === 'submitted' ? 'submitted' : 'paid';
            this.policyDateType = detail.dateType;
            const scope = CACHE_TYPE.toScope(this.cacheType);
            this.policies = await this._production.searchPolicies(scope, null, this.memberId, detail.dateType, this.period?.start, this.period?.end);
            if (this.period) {
                let day = moment(this.period.start);
                const lastDay = moment(this.period.end);
                while (day.isSameOrBefore(lastDay)) {
                    let dayStart = day.clone().startOf('day');
                    let dayEnd = day.clone().endOf('day');
                    let dayPolicies = [];
                    if (detail.dateType === 'submitted') {
                        dayPolicies = this.policies.filter(x => moment(x.submittedDate).isSame(dayStart) || moment(x.submittedDate).isBetween(dayStart, dayEnd));
                    } else if (detail.dateType === 'paid') {
                        dayPolicies = this.policies.filter(x => x.paidDate && (moment(x.paidDate).isSame(dayStart) || moment(x.paidDate).isBetween(dayStart, dayEnd)));
                    } else if (detail.dateType === 'chargeback') {
                        dayPolicies = this.policies.filter(x => x.chargebackDate && (moment(x.chargebackDate).isSame(dayStart) || moment(x.chargebackDate).isBetween(dayStart, dayEnd)));
                    }
                    let totalPremium = 0;
                    if (detail.dateType === 'submitted') {
                        dayPolicies.forEach(p => totalPremium += p.submittedPremium);
                    } else {
                        dayPolicies.forEach(p => totalPremium += p.paidPremium);
                    }
                    const dayData = {
                        id: day.valueOf(),
                        name: day.format('l'),
                        value: totalPremium
                    };
                    this.chartData.push(dayData);
                    day = day.add(1, 'day');
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    closeClickthrough() {
        this.show = 'status';
    }
}

import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { Members } from 'services/members';
import { Dialer, DIALER_TEAM } from 'services/dialer';
import { Notifier } from 'common/ui';
import { TIME_PERIOD } from 'common/constants';
import { c } from 'common/common';

export class AgentCard {
    static inject = [Element, EventAggregator, Api, Security, Members, Dialer, Notifier];
    _element;
    _members;
    _dialer;
    _notifier;
    @bindable agentId;
    agent = null;
    tab;
    tabView = '';
    tabViewModel = null;
    isAdmin = false;
    showEditLink = false;

    tabs = [
        { key: 'overview', title: 'overview', viewModel: PLATFORM.moduleName('./controls/overview'), display: true, hideForProspect: false },
        { key: 'performance', title: 'profile-performance', viewModel: PLATFORM.moduleName('./tabs/tab-performance'), display: true, hideForProspect: true },
        { key: 'sites', title: 'sites', viewModel: PLATFORM.moduleName('./tabs/tab-sites'), display: true, hideForProspect: true },
        { key: 'health', title: 'profile-health', viewModel: PLATFORM.moduleName('./controls/member-health'), display: true, hideForProspect: true },
        { key: 'notes', title: 'notes', viewModel: PLATFORM.moduleName('./tabs/tab-notes'), display: true, hideForProspect: false },
        { key: 'carriers', title: 'profile-carriers', viewModel: PLATFORM.moduleName('./tabs/tab-carriers'), display: true, hideForProspect: true },
        { key: 'chart', title: 'profile-hierarchy', loadChart: true, display: true, hideForProspect: true },
        { key: 'production', title: 'profile-production', viewModel: PLATFORM.moduleName('./tabs/tab-production'), display: true, hideForProspect: true },
        { key: 'commissions', title: 'profile-commissions', viewModel: PLATFORM.moduleName('./tabs/tab-commissions'), display: true, hideForProspect: true },
        { key: 'messages', title: 'messages', viewModel: PLATFORM.moduleName('./tabs/tab-messages'), display: true, hideForProspect: true },
        { key: 'to-dos', title: 'to-dos', viewModel: PLATFORM.moduleName('./tabs/tab-to-dos'), display: true, hideForProspect: true },
        { key: 'files', title: 'files', viewModel: PLATFORM.moduleName('./tabs/tab-files'), display: true, hideForProspect: false },
        { key: 'agent-tracking-system', title: 'agent-tracking-system', viewModel: PLATFORM.moduleName('./tabs/tab-agent-tracking-system'), display: true, hideForProspect: true },
        { key: 'leads', title: 'leads', viewModel: PLATFORM.moduleName('./tabs/tab-leads'), display: true, hideForProspect: true },
    ];

    _handlers = [];

	constructor(element, ea, api, security, members, dialer, notifier) {
        this._element = element;
		this._ea = ea;
		this.api = api;
		this.security = security;
        this._members = members;
        this._dialer = dialer;
        this._notifier = notifier;
        if (!this.security.canSeeCommissions()) this.tabs.find(x => x.key === 'commissions').display = false;
        if (!this.security.agent.onboardingComplete) this.tabs.find(x => x.key === 'sites').display = false;
        if (!this.security.isAdmin) this.tabs.find(x => x.key === 'performance').display = false;
	}

    activate(model) {
		if (model && model.notifyKey) {
            this._notifyKey = model.notifyKey;
            this._notifyType = model.notifyType;
            model.tab = { key: 'sites' };
            model.id = this.security.authenticatedMemberId;
		}
        const activateTab = model.tab
            ? typeof model.tab === 'string' ? model.tab : model.tab.key || this.tabs[0].key
            : this.tabs[0].key;
        this.tab = activateTab ? this.tabs.find(x => x.key === activateTab) || this.tabs[0] : this.tabs[0];
        this.agentId = model.id;
        this._handlers.push(this._ea.subscribe(c.EventKeys.agent.profileUpdated, () => {
            this.agent = undefined;
            this._load();
        }));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    attached() {
        this._load();
        if (this._notifyKey && this._notifyType) {
			if (this._notifyType === 'error') {
                this._notifier.error(this._notifyKey);
            } else {
                this._notifier.success(this._notifyKey);
            }
            this._notifyKey = undefined;
            this._notifyType = undefined;
        }
    }

    agentIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.agentId) return;
        try {
            this.loading = true;
    		this.agent = await this.api.get(`member/${encodeURIComponent(this.agentId)}`);
            this.tabs.forEach(t => {
                if (t.hideForProspect && this.agent.isProspect) t.display = false;
            });
            this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: { name: this.agent.fullName } }));
            this.displayTab(this.tab);
            this.showEditLink = this.security.isAuthenticatedMember(this.agentId) || this.security.isAdmin;
            this.canAutoDial = await this._dialer.canDial(DIALER_TEAM.NoPrompt);
		} catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
	}

    async autoDial() {
        try {
            const response = await this._dialer.call(undefined, DIALER_TEAM.NoPrompt, undefined, [this.agent.id]);
            if (response.succeeded) this._notifier.success('auto-dialer-success');
            else this._notifier.error(response.message);
        } catch (err) {
            console.log(err);
            this._notifier.error('auto-dialer-error');
        }
    }

    editProfile(ev) {
    }

    viewSettings() {
        this.displayTab({ key: 'settings', title: 'agent-settings', viewModel: PLATFORM.moduleName('./tabs/tab-settings') });
    }

	displayTab(tab) {
        this.tab = tab || this.tabs[0];
        this.tabModel = this.tabModel || {};
        this.tabViewModel = this.tab.viewModel;
	    switch (this.tab.key) {
	        case 'overview':
	            this.tabModel = this.agent;
	            break;
            case 'performance':
                this.tabModel = Object.assign(this.tabModel, { memberId: this.agent.id });
                break;
            case 'health':
                this.tabModel = this.agent;
                break;
            case 'notes':
                this.tabModel = { memberId: this.agent.id, security: this.security };
                break;
            case 'carriers':
                this.tabModel = Object.assign(this.tabModel, { memberId: this.agent.id, member: this.agent });
                break;
            case 'chart':
                this.tabModel = Object.assign(this.tabModel, {});
                break;
            case 'production':
                this.tabModel = Object.assign(this.tabModel, { memberId: this.agent.id, timePeriod: TIME_PERIOD.YTD });
                break;
            case 'commissions':
                this.tabModel = Object.assign(this.tabModel, { memberId: this.agent.id });
                break;
            case 'messages':
                this.tabModel = { memberId: this.agent.id };
                break;
            case 'to-dos':
                this.tabModel = { memberId: this.agent.id, security: this.security };
                break;
            case 'files':
                this.tabModel = { agentId: this.agent.id };
                break;
            case 'sites':
                this.tabModel = { agentId: this.agent.id };
                break;
            case 'leads':
                this.tabModel = { agentId: this.agent.id };
                break;
            default:
                this.tabModel = this.agent;
                break;
        }
	}

    downloadVCard() {
        this._members.downloadVCard(this.agent.id);
    }

    shareLink() {
        let agencyFirstName =  '';
        let agencyLastName = '';
        let agencyEmail = '';
        let agencyCell = '';
        if (this.agent.agency) {
            agencyFirstName = this.agent.agency.firstName;
            agencyLastName = this.agent.agency.lastName;
            agencyEmail = this.agent.agency.email;
            agencyCell = this.agent.agency.phone;
        }
        const shareAgent = JSON.parse(JSON.stringify(this.agent));
        shareAgent.agencyFirstName = agencyFirstName;
        shareAgent.agencyLastName = agencyLastName;
        shareAgent.agencyEmail = agencyEmail;
        shareAgent.agencyCell = agencyCell;
        this.security.openShareLink(shareAgent, 3000); // above the 2002 agent card
    }
}

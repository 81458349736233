import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Security } from 'common/security';
import { Agency } from 'services/agency';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import moment from 'moment';
import { c } from 'common/common';
import { REPORT_TIME_PERIOD } from 'common/constants';

export class AgencyMonthlyBonus extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, I18n, Security, Agency]

    _i18n;
    _security;
    _agency;

    teamOptions;
    teamSummary = false;
    start;
    end;

    columns;
    gridData;
    emptyGridMessage;
    loadingTimestamp;

    timePeriod = REPORT_TIME_PERIOD.MTD;

    constructor(ea, i18n, security, agency) {
        super('agency-agent-monthly-bonus', ea);
        this._i18n = i18n;
        this._security = security;
        this._agency = agency;

        this._initialize();
    }

    _initialize() {
        this.emptyGridMessage = this._i18n.tr('agency-agents-monthly-bonus-none-found');
        this.columns = [];
        this.columns.push(c.FlexGrid.column('agent', c.FlexGrid.sizes.medium, this._i18n.tr('agent'), c.FlexGrid.types.member, null));
        this.columns.push(c.FlexGrid.column('apps', c.FlexGrid.sizes.small, this._i18n.tr('apps'), c.FlexGrid.types.string));
        this.columns.push(c.FlexGrid.column('persistency', c.FlexGrid.sizes.small, this._i18n.tr('persistency'), c.FlexGrid.types.number, { postfix: '%' }));
        this.columns.push(c.FlexGrid.column('placement', c.FlexGrid.sizes.small, this._i18n.tr('placement'), c.FlexGrid.types.number, { postfix: '%' }));
        this.columns.push(c.FlexGrid.column('bonus', c.FlexGrid.sizes.small, this._i18n.tr('bonus'), c.FlexGrid.types.money, null));
    }

    attached() {
        this._attached();
        this._loadData();
    }

    async _loadData() {
        try {
            this.loadingTimestamp = moment().unix();
            this.initializeTimePeriodNavigation();

            this.report = await this._agency.agentsMonthlyBonus(this._security.authenticatedMemberId, this.asOfDate.toISOString(true));

            this.currentPeriod = this.report.bonusPeriod;
            this.setTimePeriodNavigationDisplays();

            let gridData = [];
            for (let record of this.report.agents) {
                gridData.push({
                    highlight: false,
                    values: [
                        c.FlexGrid.cellData('id', record.agent.id),
                        c.FlexGrid.cellData('agent', record.agent),
                        c.FlexGrid.cellData('apps', record.bonus.apps, record.bonus.requiredAppsReached ? 'lpfn-success' : 'lpfn-error'),
                        c.FlexGrid.cellData('persistency', record.bonus.persistencyPercentage, record.bonus.persistencyMinimumReached ? 'lpfn-success' : 'lpfn-error'),
                        c.FlexGrid.cellData('placement', record.bonus.placementPercentage, record.bonus.placementMinimumReached ? 'lpfn-success' : 'lpfn-error'),
                        c.FlexGrid.cellData('bonus', record.bonus.currentBonus),
                    ]
                });
            }
            this.gridData = gridData;
        } catch (err) {
            console.log(err);
        }
    }
}

import { observable, computedFrom } from 'aurelia-framework';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { Analytics } from 'services/analytics';
import { Policies } from 'services/policies';
import { Notifier } from 'common/ui';
import { REPORT_TIME_PERIOD, ROLE } from 'common/constants';
import { LinkCallbackRenderer } from 'resources/elements/analytics/analytics-cell-renderers';
import moment from 'moment';

export class PolicyGrid {
    static inject = [Security, I18n, Analytics, Policies, Notifier];
    _security;
    _i18n;
    _analytics;
    _policies;
    _notifier;

    asOfDate = moment();
    start;
    end;

    timePeriod;
    timePeriods = [];
    @observable customRange;
    groupBy;
    groupBys = [];

    cols;
    policies;
    selectedIds = [];
    canConvertToLeads = false;

    constructor(security, i18n, analytics, policies, notifier) {
        this._security = security;
        this._i18n = i18n;
        this._analytics = analytics;
        this._policies = policies;
        this._notifier = notifier;
        this._initializeTimePeriods();
        this._initializeGroupBys();
        this.canConvertToLeads = this._security.isInRole(ROLE.LeadAdmin);
    }

    _initializeTimePeriods() {
        // Set up 4 weeks
        let endOfWeek;
        for (let i = 0; i < 7; i++) {
            const day = moment().add(i, 'days');
            if (day.weekday() !== 4) continue;
            endOfWeek = day;
            break;
        }
        for (let i = 0; i < 4; i++) {
            if (i > 0) endOfWeek = endOfWeek.clone().add(-1, 'weeks');
            const startOfWeek = endOfWeek.clone().add(-6, 'days');
            this.timePeriods.push({ display: `${startOfWeek.format('l')} - ${endOfWeek.format('l')}`, timePeriod: REPORT_TIME_PERIOD.WTD, asOfDate: endOfWeek.clone().startOf('day') });
        }

        // Set up 3 months
        let firstOfMonth = moment().startOf('month');
        let endOfMonth = this._lastThursdayOfMonth(firstOfMonth);
        if (moment().isAfter(endOfMonth)) {
            firstOfMonth = firstOfMonth.add(1, 'month');
            endOfMonth = this._lastThursdayOfMonth(firstOfMonth);
        }
        const timePeriodIndex = this.timePeriods.length;
        for (let i = 0; i < 4; i++) {
            if (i > 0) firstOfMonth = firstOfMonth.clone().add(-1, 'months');
            this.timePeriods.push({ display: firstOfMonth.format('MMM YYYY'), timePeriod: REPORT_TIME_PERIOD.MTD, asOfDate: firstOfMonth.clone() });
        }

        // Set up 2 years
        let firstOfYear = moment().startOf('year');
        let endOfYear = this._lastThursdayOfYear(firstOfYear);
        if (moment().isAfter(endOfYear)) {
            firstOfYear = firstOfYear.add(1, 'year');
            endOfYear = htis._lastThursdayOfYear(firstOfYear);
        }
        for (let i = 0; i < 2; i++) {
            if (i > 0) firstOfYear = firstOfYear.clone().add(-1, 'years');
            this.timePeriods.push({ display: firstOfYear.format('YYYY'), timePeriod: REPORT_TIME_PERIOD.YTD, asOfDate: firstOfYear.clone() });
        }

        // Custom
        this.timePeriods.push({ display: 'Custom', timePeriod: undefined, asOfDate: undefined });

        this.timePeriod = this.timePeriods[timePeriodIndex];
    }

    _lastThursdayOfMonth(day) {
        let endOfMonth;
        day = day.clone().endOf('month');
        for (let i = 0; i > -31; i--) {
            day = day.clone().add(i, 'days');
            if (day.weekday() !== 4) continue;
            endOfMonth = day;
            break;
        }
        return endOfMonth;
    }

    _lastThursdayOfYear(day) {
        let endOfYear;
        day = day.clone().endOf('year');
        for (let i = 0; i > -31; i--) {
            day = day.clone().add(i, 'days');
            if (day.weekday() !== 4) continue;
            endOfYear = day;
            break;
        }
        return endOfYear;
    }

    _initializeGroupBys() {
        this.groupBys.push({ display: 'All policies', column: undefined });
        this.groupBys.push({ display: 'Carrier Report', column: 'carrier' });
        this.groupBys.push({ display: 'Line of Business Report', column: 'lineOfBusiness' });
        this.groupBys.push({ display: 'Writing Agent Report', column: 'writingAgent' });
        this.groupBys.push({ display: 'Team Report', column: 'team' });
        this.groupBys.push({ display: 'Agency Report', column: 'agency' });
        this.groupBys.push({ display: 'Master Agency Report', column: 'masterAgency' });
        this.groupBys.push({ display: 'Weekly Report', column: 'week' });
        this.groupBys.push({ display: 'Monthly Report', column: 'month' });
        //this.groupBys.push({ display: 'Yearly Report', column: 'year' });
        this.groupBy = this.groupBys[0];
    }

    activate() {
        this._initializeGrid();
        this._load();
    }

    customRangeChanged() {
        if (!this.customRange) {
            this.start = undefined;
            this.end = undefined;
            return;
        }
        this.start = this.customRange[0] ? this.customRange[0].format('l') : undefined;
        this.end = this.customRange[1] ? this.customRange[1].format('l') : undefined;
        this._load();
    }

    setTimePeriod(timePeriod) {
        this.timePeriod = timePeriod;
        if (!this.timePeriod.timePeriod && !this.start && !this.end) return;
        this._load();
    }

    setGroupBy(groupBy) {
        this.groupBy = groupBy;
        this.groupByColumn = groupBy.column;
    }

    async _load() {
        try {
            this.policies = await this._analytics.forPolicies(this.timePeriod.timePeriod, this.timePeriod.asOfDate, this.start, this.end);
        } catch (err) {
            console.log(err);
        }
    }

    @computedFrom('canConvertToLeads', 'selectedIds')
    get enableConvertToLeads() {
        return this.selectedIds && this.selectedIds.length && this.canConvertToLeads;
    }

    onSelected(data) {
        this.selectedIds = data.ids;
    }

    async convertToLeads() {
        try {
            await this._policies.convertToLeads(this.selectedIds);
            this._notifier.success('convert-policies-to-leads-success');
        } catch (err) {
            console.log(err);
        }
    }

    _initializeGrid() {
        this.cols = [
            {
                field: 'submittedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('submitted'),
                cellDataType: 'dateString',
                hide: true
            },
            {
                field: 'status',
                type: '',
                headerName: this._i18n.tr('status'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'paidDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('paid'),
                cellDataType: 'dateString',
                hide: true
            },
            {
                field: 'carrier',
                headerName: this._i18n.tr('carrier'),
                enableRowGroup: true
            },
            {
                field: 'policyNumber',
                type: '',
                headerName: this._i18n.tr('policy-number'),
                cellDataType: 'text',
                filter: 'agTextColumnFilter',
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'policyNumberCallback' },
                hide: true,
                headerCheckboxSelection: this.canConvertToLeads,
                headerCheckboxSelectionFilteredOnly: this.canConvertToLeads,
                checkboxSelection: this.canConvertToLeads,
            },
            {
                field: 'writingAgent',
                type: '',
                headerName: this._i18n.tr('writing-agent'),
                cellDataType: 'text',
                enableRowGroup: true,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'writingAgentCallback' },
                hide: true
            },
            {
                field: 'percentage',
                type: 'numericColumn',
                headerName: this._i18n.tr('split-percentage'),
                cellDataType: 'percentage',
                filter: 'agTextColumnFilter',
                hide: true
            },
            {
                field: 'splitWritingAgent',
                type: '',
                headerName: this._i18n.tr('split-writing-agent'),
                cellDataType: 'text',
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'splitWritingAgentCallback' },
                hide: true
            },
            {
                field: 'splitPercentage',
                type: 'numericColumn',
                headerName: this._i18n.tr('split-percentage'),
                cellDataType: 'percentage',
                filter: 'agTextColumnFilter',
                hide: true
            },
            {
                field: 'team',
                type: '',
                headerName: this._i18n.tr('production-team'),
                cellDataType: 'text',
                enableRowGroup: true,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'teamCallback' },
                hide: true
            },
            {
                field: 'agency',
                type: '',
                headerName: this._i18n.tr('agency'),
                cellDataType: 'text',
                enableRowGroup: true,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'agencyCallback' },
                hide: true
            },
            {
                field: 'masterAgency',
                type: '',
                headerName: this._i18n.tr('production-master-agency'),
                cellDataType: 'text',
                enableRowGroup: true,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'masterAgencyCallback' },
                hide: true
            },
            {
                field: 'lineOfBusiness',
                type: '',
                headerName: this._i18n.tr('line-of-business'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'product',
                type: '',
                headerName: this._i18n.tr('product'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'productType',
                type: '',
                headerName: this._i18n.tr('product-type'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'submittedActual',
                type: 'numericColumn',
                headerName: this._i18n.tr('submitted-actual-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'submittedGross',
                type: 'numericColumn',
                headerName: this._i18n.tr('submitted-gross-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'submittedBonus',
                type: 'numericColumn',
                headerName: this._i18n.tr('submitted-bonus-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'issuedActual',
                type: 'numericColumn',
                headerName: this._i18n.tr('issued-actual-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'issuedGross',
                type: 'numericColumn',
                headerName: this._i18n.tr('issued-gross-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'issuedPromotion',
                type: 'numericColumn',
                headerName: this._i18n.tr('issued-promotion-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'issuedHierarchyBonus',
                type: 'numericColumn',
                headerName: this._i18n.tr('issued-hierarchy-bonus-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'contestBonus',
                type: 'numericColumn',
                headerName: this._i18n.tr('contest-bonus-abbr'),
                cellDataType: 'money',
                aggFunc: 'sum',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'week',
                type: '',
                headerName: this._i18n.tr('widget-period-week'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'month',
                type: '',
                headerName: this._i18n.tr('widget-period-month'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'year',
                type: '',
                headerName: this._i18n.tr('widget-period-year'),
                cellDataType: 'text',
                enableRowGroup: true,
                hide: true
            },
            {
                field: 'createdDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('created'),
                cellDataType: 'dateString',
                hide: true
            },
            {
                field: 'lastUpdatedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('updated'),
                cellDataType: 'dateString',
                hide: true
            },
            {
                field: 'convertedToLeadValue',
                headerName: this._i18n.tr('converted-to-lead'),
                hide: true
            },
        ];
    }
}
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { m } from 'common/models';
import { c } from 'common/common';
import { Page, Notifier } from 'common/ui';
import { Security } from 'common/security';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class EditAgentDialog {
    static inject = [EventAggregator, DialogController, Api, I18n, Page, Notifier, Security, NewInstance.of(ValidationController)];
    security;

	originalProfile = null;
	updateModel = null;
	firstName = '';
	lastName = '';
	teamName = '';
	dateOfBirth = '';
	email = '';
	streetAddress = '';
	streetAddress2 = '';
	city = '';
	state = '';
	zip = '';
	businessPhone = '';
	cellPhone = '';
    languages = '';
	isLicensedLife = false;
	isLicensedHealth = false;
	nationalProducerNumber;
    licenseFirstName;
    licenseLastName;
    residentLicenseState;
    residentLicenseNumber;

	isEditingSelf = false;
	hasValidationErrors = false;
	processing = false;

	usStates = [];

	_handlers = [];

	constructor(eventAggregator, dialogController, api, i18n, page, notifier, security, validationController) {
		this._ea = eventAggregator;
        this.dialogController = dialogController;
		this.api = api;
		this.i18n = i18n;
		this.page = page;
		this.notifier = notifier;
		this.security = security;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		this.originalProfile = new m.MemberProfile();

		this.usStates = c.Geography.usStates;

		ValidationRules
            .ensure('firstName').required()
	        .ensure('lastName').required()
	        .ensure('dateOfBirth').required()
	        .ensure('email').required().email()
	        .ensure('streetAddress').required()
	        .ensure('city').required()
	        .ensure('state').required()
	        .ensure('zip').required()
	        .ensure('businessPhone').required().satisfiesRule('phone')
	        .ensure('cellPhone').required().satisfiesRule('phone')
            .ensure('nationalProducerNumber').required().when(m => this.isEditingSelf && this.security.agent.onboardingComplete)
            .ensure('licenseFirstName').required().when(m => this.isEditingSelf && this.security.agent.onboardingComplete)
            .ensure('licenseLastName').required().when(m => this.isEditingSelf && this.security.agent.onboardingComplete)
            .on(this);
    }

	async activate(model) {
        this.agentId = model.id;
        await this.i18n.loadNamespace(['profile']);
        this.loadMember();
    }

	attached() {
		this.page.scrollToTop();
	}

	detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
	}

	async loadMember() {
        try {
    		let apiMethod = 'me';
            if (!this.security.isAuthenticatedMember(this.agentId)) apiMethod = this.agentId;
		    this.originalProfile = await this.api.get(`member/${apiMethod}`);
            this.title = this.originalProfile.fullName;
			this.setPropertiesFromProfile();
			this.isEditingSelf = this.security.isAuthenticatedMember(this.agentId);

            const emailAliases = this.originalProfile.emailAliases || [];
            this.emailAliases = [];
            emailAliases.forEach(a => this.emailAliases.push({ alias: a, focus: false }));
        } catch (err) {
			this.notifier.generalError();
		}
	}

	async updateProfile() {
		if (this.processing) return;

        try {
    		const v = await this.validationController.validate();
		    if (!v.valid) {
		        this.page.scrollToTop();
		        this.hasValidationErrors = true;
		        return;
		    }

		    this.processing = true;
		    this.hasValidationErrors = false;

		    this.fillModel();

		    await this.api.put('member', this.updateModel);
			this.security.setProfileName(this.agentId, this.updateModel.firstName, this.updateModel.lastName);
            this.notifier.success('profile-updated-message');
			this._ea.publish(c.EventKeys.agent.profileUpdated, {});
			this.dialogController.ok();
        } catch (err) {
            console.log(err);
            if (typeof err === 'string') this.notifier.error(err);
            else this.notifier.generalError();
        } finally {
            this.processing = false;
        }
	}

    addEmailAlias() {
        this.emailAliases.push({ alias: '', focus: true });
    }

    removeEmailAlias(index) {
        try {
            this.emailAliases.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }

	setPropertiesFromProfile() {
		this.firstName = this.originalProfile.firstName;
		this.lastName = this.originalProfile.lastName;
		this.teamName = this.originalProfile.teamName;
		this.email = this.originalProfile.email;
		this.dateOfBirth = new Date(this.originalProfile.dateOfBirth).toISOString().substring(0, 10);
		this.streetAddress = this.originalProfile.streetAddress;
		this.streetAddress2 = this.originalProfile.streetAddress2;
		this.city = this.originalProfile.city;
		this.state = this.originalProfile.state;
		this.zip = this.originalProfile.zip;
		this.businessPhone = this.originalProfile.businessPhone;
		this.cellPhone = this.originalProfile.cellPhone;
        this.languages = this.originalProfile.languages;
		this.isLicensedHealth = this.originalProfile.isLicensedHealth;
		this.isLicensedLife = this.originalProfile.isLicensedLife;
		this.nationalProducerNumber = this.originalProfile.nationalProducerNumber;
        this.licenseFirstName = this.originalProfile.licenseFirstName;
        this.licenseLastName = this.originalProfile.licenseLastName;
        this.residentLicenseState = this.originalProfile.residentLicenseState;
        this.residentLicenseNumber = this.originalProfile.residentLicenseNumber;
	}

	fillModel() {
		this.updateModel = new m.MemberUpdate();
		this.updateModel.id = this.originalProfile.id;
		this.updateModel.firstName = this.firstName;
		this.updateModel.lastName = this.lastName;
		this.updateModel.teamName = this.teamName;
		this.updateModel.dateOfBirth = this.dateOfBirth;
		this.updateModel.email = this.email;
		this.updateModel.streetAddress = this.streetAddress;
		this.updateModel.streetAddress2 = this.streetAddress2;
		this.updateModel.city = this.city;
		this.updateModel.state = this.state;
		this.updateModel.zip = this.zip;
		this.updateModel.businessPhone = this.businessPhone;
		this.updateModel.cellPhone = this.cellPhone;
        this.updateModel.languages = this.languages;
		this.updateModel.isLicensedLife = this.isLicensedLife;
		this.updateModel.isLicensedHealth = this.isLicensedHealth;
		this.updateModel.nationalProducerNumber = this.nationalProducerNumber;
        this.updateModel.licenseFirstName = this.licenseFirstName;
        this.updateModel.licenseLastName = this.licenseLastName;
        this.updateModel.residentLicenseState = this.residentLicenseState;
        this.updateModel.residentLicenseNumber = this.residentLicenseNumber;
        const emailAliases = [];
        this.emailAliases.forEach(a => {
            if (a.alias) emailAliases.push(a.alias);
        });
        this.updateModel.emailAliases = emailAliases;
    }
}

import { PLATFORM } from 'aurelia-pal';
import { I18n } from 'common/i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { c } from 'common/common';

export class Index {
	static inject = [I18n, EventAggregator];
	_i18n;
    _ea;

    _handlers = [];

	constructor(i18n, ea) {
		this._i18n = i18n;
        this._ea = ea;
	}

	configureRouter(config, router){
		config.map([
            {
				route: ['', 'policies'],
				moduleId: PLATFORM.moduleName('members/analytics/policy-grid'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
                settings: { title: 'analytics-policies', description: 'analytics-policies-description', canAddToMobileMenu: false, icon: 'fa-duotone fa-magnifying-glass-chart' }
			},
            {
				route: 'agents',
				moduleId: PLATFORM.moduleName('members/analytics/agent-grid'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
                settings: { title: 'Agent Analytics', description: 'View all agents', canAddToMobileMenu: false, icon: 'fa-duotone fa-magnifying-glass-chart' }
			},
		]);

		this.router = router;
	}

    attached() {
        this._setPageTitle();
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changed, () => this._setPageTitle()));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _setPageTitle() {
        try {
            const title = this.router.currentInstruction.config.settings.title;
            const description = this.router.currentInstruction.config.settings.description;
            window.setTimeout(() => this._ea.publish(c.EventKeys.site.setPageTitle, { title, description }), 0); // must be after the navigation event
        } catch (err) {
            console.log(err);
        }
    }
}

export class TabCarriers {
    member;
    memberId;

    constructor() {}

    activate(model) {
        this.member = model.member;
        this.memberId = model.memberId;
    }
}

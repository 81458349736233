import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { c } from 'common/common';

export class EditAgentProfileImageDialog {
    static inject = [EventAggregator, DialogController];
    _ea;
    dialogController;

    imageUrl = '';
	_handlers = [];

	constructor(ea, dialogController) {
		this._ea = ea;
        this.dialogController = dialogController;
    }

	async activate(model) {
        this.agentId = model.id;
        this.originalImageUrl = model.imageUrl;
        this.title = model.fullName;
        this.imageUrl = model.imageUrl;
    }

	attached() {
		this._handlers.push(this._ea.subscribe(c.EventKeys.agent.profileImageUpdated, (data) => {
			let unique = new Date().getMilliseconds();
			let img = document.getElementById('my-profile-image');
			let currentSrc = `${this.originalImageUrl}?ms=${unique}`;
			img.setAttribute('src', currentSrc);
            this.imageUrl = currentSrc;
		}));
	}

	detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
	}

    profileImageUploaded() {
        this.dialogController.ok();
    }
}

import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';

export class ProductionIndex extends RouterConfigBase {
	static inject = [EventAggregator, Security, Menus];

    constructor(ea, security, menus) {
        super(ea, security, menus, undefined, false);
	}

	configureRouter(config, router){
	    config.map([
            {
				route: '',
				moduleId: PLATFORM.moduleName('members/agent-tracking-system/dashboard'),
				nav: false,
                settings: { title: 'agent-tracking-system', description: 'agent-tracking-system-description', canAddToMobileMenu: true },
			}
	    ]);

		this.router = router;
	}

	attached() {
		this._attached();
	}

	detached() {
		this._detached();
	}
}

import {inject} from 'aurelia-framework';
import {Security} from 'common/security';
import {Notifier, Page} from 'common/ui';
import {Api} from 'common/server';
import {I18n} from 'common/i18n';

@inject(Security, Notifier, Page, Api, I18n)
export class ApprovalRequests {

    _memberId = null;
    returnLinkKey = 'profile-return-link';
    approvals = [];

    constructor(security, notifier, page, api, i18n) {
        this.security = security;
        this.notifier = notifier;
        this.page = page;
        this.api = api;
        this.i18n = i18n;
    }

    activate(params, routerConfig) {
        this._memberId = params.id;
        this.returnLinkKey = this._memberId ? 'profile-return-to-profile-link' : 'profile-return-link';
        this.returnLink = this._memberId && this.security.isAdmin ? '#/admin/team-member/' + this._memberId : '#/members/my-profile';
        this._loadApprovals();
    }

    _loadApprovals() {
        var me = this;
        var apiUrl = me._memberId ? 'approval/by-member/' + me._memberId : 'approval';
        me.api.getData(apiUrl).then(data => {
            me.approvals = data;
        }).catch(error => {
            me.notifier.generalError();
        });
    }

    toggleApproval(id) {
        for (var i = 0; i < this.approvals.length; i++) {
            if (this.approvals[i].id !== id) continue;
            this.approvals[i].show = !this.approvals[i].show;
            break;
        }
    }
}

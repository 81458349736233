import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { c } from 'common/common';

export class AgentCardDialog {
    static inject = [EventAggregator, DialogController];

    agentName;

    _handlers = [];

	constructor(ea, dialogController) {
		this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
	}

    activate(model) {
        this.agentId = model.loaded ? model.member.id : model.id;
        this._handlers.push(this._ea.subscribe(c.EventKeys.login.timedOut, () => this.dialogController.cancel()));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    agentLoaded(data) {
        this.agentName = data.name;
    }
}

export class TabLeads {
    agentId;

    constructor() {
    }

    activate(model) {
        this.agentId = model.agentId;
    }
}

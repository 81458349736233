import { ROLE } from 'common/constants';

export class TabNotes {
    memberId;
    canSeePrivateNotes = false;

    constructor() {
    }

    activate(model) {
        this.memberId = model.memberId;
        this.canSeePrivateNotes = model.security.isInRole([ROLE.BackOffice]);
    }
}

import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Members } from 'services/members';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { Onboardings } from 'services/onboardings';
import { DialogService } from 'aurelia-dialog';
import { EditAgentDialog } from './../dialogs/edit-agent-dialog';
import { ChangePasswordDialog } from './../dialogs/change-password-dialog';
import { EditAgentProfileImageDialog } from './../dialogs/edit-agent-profile-image-dialog';
import moment from 'moment';
import { c } from 'common/common';
PLATFORM.moduleName('./../dialogs/edit-agent-dialog');
PLATFORM.moduleName('./../dialogs/change-password-dialog');
PLATFORM.moduleName('./../dialogs/edit-agent-profile-image-dialog');

export class Overview {
    static inject = [EventAggregator, Members, Security, Notifier, Onboardings, DialogService];
    _ea;
    _members;
    security;
    _notifier;
    _onboardings;
    _dialogService;
    agent = null;
    canResetOnboarding = false;
    isAgencyManager = false;
    viewingMyProfile = false;

    profileActions;
    levelActions = [
        { key: 'Life', name: 'request-life-level-change' },
        { key: 'Annuity', name: 'request-annuity-level-change' },
        { key: 'HMA', name: 'request-hma-level-change' },
    ];

    _handlers = [];

    constructor(ea, members, security, notifier, onboardings, dialogService) {
        this._ea = ea;
        this._members = members;
        this.security = security;
        this._notifier = notifier;
        this._onboardings = onboardings;
        this._dialogService = dialogService;
    }

    activate(model) {
        this.agent = model;
        this._load();
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.mentor.assigned, (data) => { this.agent.mentor = data.mentor; this.reloadMentor = new Date().getTime(); }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.mentor.removed, () => { this.agent.mentor = undefined; }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
    }

    async _load() {
        try {
            this.viewingMyProfile = this.security.isAuthenticatedMember(this.agent.id);

            this.profileActions = [];
            if (this.viewingMyProfile || this.security.isAdmin) this.profileActions.push({ key: 'edit-profile-image', name: 'edit-profile-image' });
            this.profileActions.push({ key: 'edit', name: 'edit-profile' });
            this.profileActions.push({ key: 'change-password', name: 'change-password' });
    

            this.canResetOnboarding = this.agent.agency ? this.security.isAuthenticatedMember(this.agent.agency.id) : false;
            if (this.security.isAdmin) this.canResetOnboarding = true;
            this.isAgencyManager = this.agent.agency ? this.security.isAuthenticatedMember(this.agent.agency.id) : false;
            const massCommunicationStatus = await this._members.getMassCommunicationStatus(this.agent.id);
            this.mailChimpStatus = massCommunicationStatus.mailChimpStatus || 'unknown';
            this.clickSendStatus = massCommunicationStatus.clickSendStatus || 'unknown';
        } catch (err) {
            console.log(err);
        }
    }

    profileAction(key) {
        if (key === 'edit') this.openEditor();
        else if (key === 'change-password') this.openChangePassword();
        else if (key === 'edit-profile-image') this.openProfileImageEditor();
    }

    requestLevelChange() {
        this.showLevelChangeRequest = true;
    }

    cancelLevelChange() {
        this.showLevelChangeRequest = false;
    }

    sendLevelChange() {
        this._members.requestLevelChange(this.agent.id, this.requestedLevel);
    }

    async resetOnboarding() {
        try {
            this.resetting = true;
            await this._onboardings.reset(this.agent.id);
            this.refreshOnboardingMemberId = moment().format();
        } catch (err) {
			this._notifier.generalError();
        } finally {
            this.resetting = false;
        }
    }

    async checkOnboardingStatus() {
        try {
            this.checking = true;
            const status = await this._onboardings.checkContractedStatus(this.agent.id);
            if (!status) {
                this._notifier.error('contracting-status-not-available');
                return;
            }
            this._notifier.success('contracting-status-updated');
            this.refreshOnboardingMemberId = moment().format();
        } catch (err) {
            console.log(err);
        } finally {
            this.checking = false;
        }
    }

    async completeOnboarding() {
        try {
            this.completing = true;
            await this._onboardings.complete(this.agent.id);
            this.agent.isOnboarding = false;
            this.refreshOnboardingMemberId = moment().format();
        } catch (err) {
            console.log(err);
        } finally {
            this.completing = false;
        }
    }

    async toggleAgency(event) {
        try {
            await this._members.setAgency(this.agent.id, event.detail.checked);
        } catch (err) {
            console.log(err);
        }
    }

    async toggleTeam(event) {
        try {
            await this._members.setTeam(this.agent.id, event.detail.checked);
        } catch (err) {
            console.log(err);
        }
    }

    openEditor() {
        this._dialogService.open({ viewModel: EditAgentDialog, model: this.agent, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
        });
    }

    openProfileImageEditor() {
        this._dialogService.open({ viewModel: EditAgentProfileImageDialog, model: this.agent, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
        });
    }

	openChangePassword() {
	    this._dialogService.open({ viewModel: ChangePasswordDialog, model: { currentPassword: '', newPassword: '' }, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	    });
	}
}

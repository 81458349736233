export class TabProduction {
    memberId;

    constructor() {}

    activate(model) {
        this.memberId = model.memberId;
        this.timePeriod = model.timePeriod;
        this.summaryType = model.summaryType;
        this.date = model.date;
    }
}
